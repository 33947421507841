<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.请假变更审批')"
                 fixed
                 right-:text="$t('tbm.取消')"
                 @click-left="$router.go(-1)"
                 @click-right="$router.go(-1)">
    </van-nav-bar>
    <div :style="{paddingTop:'46px',paddingBottom:approvalShow ? '50px' : '0'}"
         class="applyHolidayChangeView">
      <div>
        <div>
          <p>
            <i class="iconfont icon-qingxiujiashenqing"></i>
          </p>
          <div>
            <p>{{ $t('tbm.请休假变更申请') }}</p>
            <p>{{ $t('tbm.申请时间') }}：{{ruleForm.createTime}}</p>
          </div>
        </div>
        <p :style="{color:changeForm.formColor,'font-size':'16px'}">{{changeForm.formStatusName}}</p>
      </div>
      <van-collapse v-model="activeNames">
        <van-collapse-item title=""
                           name="1">
          <template #title>
            <div style="display:flex;justify-content:space-between;">
              <span>{{ $t('tbm.原请假信息') }}</span>
            </div>
          </template>
          <div class="applyHolidayChangeViewContent">
            <div>
              <div v-for="(item,index) in dataList"
                   :key="index">
                <label>{{item.label}}</label>
                <p>{{ruleForm[item.text]}}</p>
              </div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item name="2">
          <template #title>
            <div style="display:flex;justify-content:space-between;">
              <span>{{ $t('tbm.变更信息') }}</span>
            </div>
          </template>
          <div class="applyHolidayChangeViewContent">
            <div>
              <div v-for="(item,index) in dataList"
                   :key="index">
                <label>{{item.label}}</label>
                <p>{{changeForm[item.text]}}</p>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <div v-if="approvalShow">
        <filesList :showFile="true"
                   v-show="filesList.length>0"
                   :filesList="filesList"></filesList>
        <history-panel :pid="changeForm.id"
                       :woType="procConf.woCode"
                       :formNo="changeForm.formNo"></history-panel>
        <approval v-if="changeForm"
                  class="approvalFixed"
                  :woCodeValue="procConf.woCode"
                  :applyChangeNo="ruleForm.id"
                  saveUrl="applyHoliday"
                  :businessInfo="changeForm"
                  :detailPageName="$route.name"
                  :customSubmitBtnName="customSubmitBtnName"
                  :processName="procConf.processInstanceName"
                  :taskId="procConf.taskInstanceId">
        </approval>
      </div>
    </div>
  </div>
</template>
<script>
import editComponent from './components/editComponent';
import approvalButton from '@/views/business/components/approvalButton';
import approval from '@/components/approval/approval';
import filesList from '@/views/business/components/filesList';
import historyPanel from '@/views/business/components/historyPanel';
export default {
  components: {
    editComponent,
    approvalButton,
    approval,
    filesList,
    historyPanel
  },
  data () {
    return {
      activeNames: ['2'],
      ruleForm: {
        id: '',
        createTime: '',
        formStatusName: '',
        formColor: '',
        userName: '',
        deptName: '',
        holidayName: '',
        beginDate: '',
        endDate: '',
        totalDays: '',
        reason: '',
        formNo: '',
        formStatusName: '',
        formColor: ''
      },
      dataList: [
        {
          label: '申请人',
          text: 'userName'
        },
        {
          label: '所在部门',
          text: 'deptName'
        },
        {
          label: '休假类型',
          text: 'holidayName'
        },
        {
          label: '开始时间',
          text: 'beginDate'
        },
        {
          label: '结束时间',
          text: 'endDate'
        },
        {
          label: '折合天数',
          text: 'totalDays'
        },
        {
          label: '请假原因',
          text: 'reason'
        }
      ],
      formStatus: [
        { label: '暂存', color: '#318B80' },
        { label: '审核中', color: '#FF7405' },
        { label: '作废', color: '#999999' },
        { label: '通过审核', color: '#4378BE' },
      ],
      changeForm: {
        id: '',
        pkOrg: '',
        pkDept: '',
        pkUser: '',
        holidayName: '',
        holidayType: '',
        beginDate: '',
        endDate: '',
        totalDays: '',
        formStatus: '0'
      },
      applyHolidayChangeList: [],
      filesList: [],
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
      procConf: {
        businessKey: '',
        processInstanceName: '请休假变更',
        woCode: 'TbmapplyHoliday', //对应不同的请假类型
        taskInstanceId: '',
        variables: {}, //用于流程条件判断
        taskType: '',
        mappingId: '',
        status: '',
        activityId: ''
      },
    }
  },
  methods: {
    getFilesList () {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/sysAttchement/list',
        data: {
          'entity': {
            'bizId': this.changeForm.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.filesList = backData
        }
      });
    },
    getApplyWoCode (type) {
      //先处理特殊用户
      if (this.deptAndPost === this.TbmCons.deptAndPost.CEO || this.deptAndPost === this.TbmCons.deptAndPost.CEOSENCOND || this.deptAndPost === this.TbmCons.deptAndPost.CHAIRMANSENCODE) {
        this.procConf.woCode = this.TbmCons.leaveType.GroupLeaderLeave
        return false
      }
      else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_MATERNITY || type === this.TbmCons.holidayType.HOLIDAY_TYPE_PATERNITY) {
        // 生育陪产
        this.procConf.woCode = this.TbmCons.leaveType.MaternityLeave;
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_WEDDING || type === this.TbmCons.holidayType.HOLIDAY_TYPE_FUNERAL || type === this.TbmCons.holidayType.HOLIDAY_TYPE_FAMILY) {
        // 婚 丧 探亲假
        this.procConf.woCode = this.TbmCons.leaveType.FuneralLeave
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_ANNUAL || type === this.TbmCons.holidayType.HOLIDAY_TYPE_PUBLIC) {
        // 公假 年休假
        this.procConf.woCode = this.TbmCons.leaveType.AnnualLeave
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_LEAVE || type === this.TbmCons.holidayType.HOLIDAY_TYPE_SICK) {
        //事假 病假
        this.procConf.woCode = this.TbmCons.leaveType.SickLeave
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_INJURY) {
        //工伤假
        // this.procConf.woCode= this.TbmCons.leaveType.InjuryLeave
        this.procConf.woCode = 'TbmInjuryLeave'
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_ADJUST) {
        //调休假
        this.procConf.woCode = this.TbmCons.leaveType.VacationLeave
      }
      else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_CHAPERONE) {
        //陪护假
        this.procConf.woCode = this.TbmCons.leaveType.ChaperoneLeave
      }
      if (this.$route.query.type) {
        this.approvalShow = true;
      }
    },
  },
  created () {
    this.ruleForm.id = this.$route.query.businessKey;
    if (this.$route.query['row']) {
      let row = JSON.parse(this.$route.query['row']);
      if (row && row['pid']) {
        this.ruleForm.id = this.EhrUtils.isEmpty(this.ruleForm.id) ? row['businessKey'] : this.ruleForm.id;
        this.procConf.taskInstanceId = row['id'];
        this.procConf.taskType = row['taskType'];
        this.procConf.status = row['status'];
        this.procConf.activityId = row['activityId'];
        this.procConf.activityCode = row['activityCode'];// 节点编码
      }
    }
    // 获取变更父id
    if (this.$route.query.pkParent) {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyHoliday/getChangeViewList',
        data: {
          'entity': {
            'id': this.$route.query.pkParent
          }
        }
      }).then((res) => {
        // 变更的两条记录
        let holidayList = res.data.changeViewList;
        //   for (let i = 0; i < x.length; i++) {
        this.applyHolidayChangeList = holidayList;
        this.ruleForm = { ...this.applyHolidayChangeList[0] };

        this.changeForm = { ...this.applyHolidayChangeList[0], id: '' };
        let newFormNo = this.applyHolidayChangeList[holidayList.length - 1].formNo
        if (newFormNo.indexOf('-V') > -1) {
          let newFormNoArray = newFormNo.split('-V')
          let version = '-V' + (Number(newFormNoArray[1]) + 1) + '.0';
          newFormNo = newFormNoArray[0] + version;
        } else {
          newFormNo = newFormNo + '-V1.0'
        }
        this.changeForm.formNo = newFormNo;
        //  }
        //将上一版的请假类型、请假日期设置到本次变更

        //如果上一版驳回则取原始版本变更
        if (holidayList[holidayList.length - 1].formStatus === '2' && holidayList.length > 1) {
          this.changeForm.holidayType = holidayList[holidayList.length - 2].holidayType;
          this.changeForm.totalDays = holidayList[holidayList.length - 2].totalDays;
          // this.selectTime.push(holidayList[holidayList.length - 2].beginDate);
          // this.selectTime.push(holidayList[holidayList.length - 2].endDate);
          this.changeForm.beginDate = holidayList[holidayList.length - 2].beginDate;
          this.changeForm.endDate = holidayList[holidayList.length - 2].endDate;
          this.changeForm.applyHolidayDetails = holidayList[holidayList.length - 2].applyHolidayDetails;
          this.changeForm.applyHolidayDetails.forEach(res => {
            res.id = '';
          })

        } else {
          this.changeForm.holidayType = holidayList[holidayList.length - 1].holidayType;
          this.changeForm.totalDays = holidayList[holidayList.length - 1].totalDays;
          // this.selectTime.push(holidayList[holidayList.length - 1].beginDate);
          // this.selectTime.push(holidayList[holidayList.length - 1].endDate);
          this.changeForm.beginDate = holidayList[holidayList.length - 1].beginDate;
          this.changeForm.endDate = holidayList[holidayList.length - 1].endDate;
          this.changeForm.applyHolidayDetails = holidayList[holidayList.length - 1].applyHolidayDetails;
          this.changeForm.applyHolidayDetails.forEach(res => {
            res.id = '';
          })
        }
        this.changeForm.formStatus = '0';
        // 详情赋值
        if (this.applyHolidayChangeList.length > 1) {
          this.ruleForm = { ...this.applyHolidayChangeList[this.applyHolidayChangeList.length - 2] };
          this.changeForm = { ...this.applyHolidayChangeList[this.applyHolidayChangeList.length - 1] };
          this.getFilesList();
          this.getApplyWoCode(this.changeForm.holidayType)
        }
        this.ruleForm.formStatusName = this.formStatus[this.ruleForm.formStatus * 1].label;
        this.ruleForm.formColor = this.formStatus[this.ruleForm.formStatus].color;
        this.changeForm.formStatusName = this.formStatus[this.changeForm.formStatus].label;
        this.changeForm.formColor = this.formStatus[this.changeForm.formStatus].color;
        this.changeForm.pkHolidayChange = this.$route.query.pkParent;
        //历史变更记录
        if (res.data.isChange) {
          this.applyHolidayChangeList = res.data.changeViewAllList;
        }

      })

    }
    
    let deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
    let userName = JSON.parse(localStorage.getItem('userInfo')).cname;
    let officeoutReason=this.changeForm.reason;
    if(officeoutReason.length>5){
      officeoutReason=officeoutReason.substring(0.,5)+'……'
    }
    this.procConf.processInstanceName =this.procConf.processInstanceName+'-'+deptName+' '+userName+'-'+officeoutReason+'-'+this.changeForm.totalDays+'天'
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == "approvalPanel") {
      next();
    } else {
      sessionStorage.removeItem('formData');
      next();
    }
  },
}
</script>
<style>
.applyHolidayChangeView > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
  background: #fff;
  margin-bottom: 10px;
}
.applyHolidayChangeView > div:nth-child(1) > div {
  display: flex;
  align-items: center;
}
.applyHolidayChangeView > div:nth-child(1) > div > p {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(249, 156, 156);
  margin-right: 10px;
}
.applyHolidayChangeView > div:nth-child(1) > div > p i {
  width: 27px;
  height: 27px;
  font-size: 27px;
  color: #fff;
}
.applyHolidayChangeView > div:nth-child(1) > div > div {
  font-size: 18px;
  color: rgb(51, 51, 51);
}
.applyHolidayChangeView > div:nth-child(1) > div > div p:nth-child(2) {
  font-size: 14px;
  color: rgb(102, 102, 102);
  margin-top: 5px;
}
.applyHolidayChangeViewContent > div:nth-child(1) {
  background: #fff;
}
.applyHolidayChangeViewContent > div:nth-child(1) > div {
  height: 40px;
  display: flex;
  align-items: center;
}
.applyHolidayChangeViewContent > div:nth-child(1) > div label {
  margin-right: 20px;
  text-align: right;
  color: rgb(102, 102, 102);
  font-size: 14px;
  width: 70px;
}
.applyHolidayChangeViewContent > div:nth-child(1) > div p {
  color: rgb(102, 102, 102);
  font-size: 14px;
}
.applyHolidayChangeView .van-collapse-item__content {
  padding: 0 5px;
}
.applyHolidayChangeView .van-collapse-item__title .van-cell__title,
.applyHolidayChangeView .van-collapse-item__title--disabled .van-cell__title {
  color: rgb(102, 102, 102);
  font-weight: 600;
  font-size: 16px;
}
.applyHolidayChangeView .van-collapse-item__title .van-cell__title span,
.applyHolidayChangeView .van-collapse-item__title--disabled .van-cell__title span {
  font-size: 14px;
  font-weight: 400;
}
</style>
